import { lazy } from "react";

import AuthGuard from "./AuthGuard";
import PageRouter from "../pages/pageRouter/PageRouter";
import ComingSoon from "../components/comingSoon/ComingSoon";
import { GroupProvider } from "../data/groupContextApi";
import StatusBoard from "../pages/statusBoard/StatusBoard";
import StatusBoardColumn from "../pages/statusBoardColumn/StatusBoardColumn";
import { CareTeams } from "../pages/careTeams/CareTeams";
import { ReportsManagement } from "../pages/reportsManagement/ReportsManagement";
import { EmsReportManagement } from "../pages/emsReportManagement/EmsReportManagement";
import BedReport from "../pages/bedReport/BedReport";
import { ScheduleReport } from "../pages/scheduleReport/ScheduleReport";
import { DashBoardTable } from "../pages/dashboard/components/dashboardTable/DashBoardTable";
import { AssignNurse } from "../pages/assignNurse/AssignNurse";
import { PublicHolidays } from "../pages/publicHolidays/PublicHolidays";
import { Help } from "../pages/help/Help";
import EmptyPage from "../components/empty/EmptyPage";
import Spinner from "../components/spinner/Spinner";

const LandingPage = lazy(() => import("../pages/landingPage/LandingPage"));
const LoginForm = lazy(() => import("../pages/login/LoginForm"));
const LoginRecover = lazy(() => import("../pages/login/components/forgotPassword/LoginRecover"));
const ResetPassword = lazy(() => import("../pages/login/components/forgotPassword/ResetPassword"));

const Home = lazy(() => import("../pages/home/Home"));

const User = lazy(() => import("../pages/user/User"));
const AddUser = lazy(() => import("../pages/user/components/addUser/AddUser"));
const UploadFile = lazy(() => import("../pages/user/components/uploadFile/UploadFile"));
const UserRule = lazy(() => import("../pages/userRules/UserRule"));

const AddSites = lazy(() => import("../pages/sites/components/addSites/AddSites"));
const Sites = lazy(() => import("../pages/sites/Sites"));

const Speciality = lazy(() => import("../pages/speciality/Speciality"));
const AddSpeciality = lazy(() => import("../pages/speciality/components/addSpeciality/components/AddSpeciality"));

const AddRole = lazy(() => import("../pages/roles/components/addRole/AddRole"));
const RoleChange = lazy(() => import("../pages/roles/components/roleChange/RoleChange"));
const AddRoleBasedPermission = lazy(() =>
  import("../pages/roles/components/roleChange/components/components/AddRoleBasedPermission")
);

const Group = lazy(() => import("../pages/group/Group"));
const AddUserGroup = lazy(() => import("../pages/group/components/addGroup/AddUserGroup"));
const UnAssignedShiftTable = lazy(() =>
  import("../pages/group/components/groupSchedule/components/UnAssignedShiftTable")
);
const UnAssignedShiftRequestCover = lazy(() =>
  import("../pages/group/components/groupSchedule/components/UnAssignedShiftRequestCover")
);
const AddGroup = lazy(() => import("../pages/group/components/addGroup/AddGroupCard"));
const AddSchedule = lazy(() => import("../pages/group/components/addSchedule/AddScheduleCard"));
const Shift = lazy(() => import("../pages/shifts/Shift"));
const Scheduler = lazy(() => import("../pages/shifts/scheduler/Scheduler"));

const UnitDepartments = lazy(() => import("../pages/unitDepartments/UnitDepartments"));

const SupportStaff = lazy(() => import("../pages/supportStaff/SupportStaff"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));

export const routes = [
  {
    path: "/login",
    element: <LandingPage />,
    children: [
      {
        path: "",
        element: <LoginForm />,
      },
    ],
  },
  {
    path: "/forgot-password",
    element: <LandingPage />,
    children: [
      {
        path: "",
        element: <LoginRecover />,
      },
    ],
  },
  {
    path: "/forgot-username",
    element: <LandingPage />,
    children: [
      {
        path: "",
        element: <LoginRecover />,
      },
    ],
  },
  {
    path: "/auth/reset-password/:token/:code",
    element: <LandingPage />,
    children: [
      {
        path: "",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ComingSoon />,
    element: (
      <AuthGuard>
        <GroupProvider>
          <PageRouter />
        </GroupProvider>
      </AuthGuard>
    ),

    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "dashboard/providers",
        element: <DashBoardTable />,
      },
      {
        path: "users",
        element: <User />,
      },
      {
        path: "groups",
        element: <Group />,
      },
      {
        path: "shift",
        element: <Shift />,
      },
      {
        path: "shift/:scheduleId",
        element: <Shift />,
      },
      {
        path: "sites",
        element: <Sites />,
      },
      {
        path: "speciality",
        element: <Speciality />,
      },
      {
        path: "units-departments",
        element: <UnitDepartments />,
      },
      {
        path: "assign-nurse",
        element: <AssignNurse />,
      },
      {
        path: "support-staffs",
        element: <SupportStaff />,
      },
      {
        path: "care-teams",
        element: <CareTeams />,
      },
      {
        path: "status-board-columns",
        element: <StatusBoardColumn />,
      },
      {
        path: "status-boards",
        element: <StatusBoard />,
      },
      {
        path: "bed-report",
        element: <ComingSoon />,
      },
      {
        path: "sites/add-sites",
        element: <AddSites />,
      },
      {
        path: "sites/edit-sites/:siteId?",
        element: <AddSites />,
      },
      {
        path: "add-speciality",
        element: <AddSpeciality />,
      },
      {
        path: "edit-speciality/:specialityId?/:roleName?",
        element: <AddSpeciality />,
      },
      {
        path: "roles",
        element: <RoleChange />,
      },
      {
        path: "add-role",
        element: <AddRole />,
      },
      {
        path: "roles/edit-role/:roleID",
        element: <AddRole />,
      },
      {
        path: "add-role-based-permission",
        element: <AddRoleBasedPermission />,
      },
      {
        path: "edit-role-based-permission/:rolebasedId?",
        element: <AddRoleBasedPermission />,
      },
      {
        path: "users/add-user",
        element: <AddUser />,
      },
      {
        path: "users/edit-user/:userId?",
        element: <AddUser />,
      },
      {
        path: "users/upload-file",
        element: <UploadFile />,
      },
      {
        path: "*",
        element: <ComingSoon />,
      },
      {
        path: "groups/add-group",
        element: <AddGroup />,
      },
      {
        path: "groups/edit-group/:groupId",
        element: <AddGroup />,
      },
      {
        path: "groups/update-user/:groupIdToUpdateUser",
        element: <AddGroup />,
      },
      {
        path: "groups/add-schedule",
        element: <AddSchedule />,
      },
      {
        path: "groups/add-user",
        element: <AddUserGroup />,
      },
      {
        path: "shift/scheduler",
        element: <Scheduler />,
      },
      {
        path: "shift/scheduler/:schedulerId",
        element: <Scheduler />,
      },
      {
        path: "users/user-rule/:userId?",
        element: <UserRule />,
      },
      {
        path: "groups/assign-user/:shiftId?",
        element: <UnAssignedShiftTable />,
      },
      {
        path: "groups/req-cover/:shiftId?",
        element: <UnAssignedShiftRequestCover />,
      },
      {
        path: "reports-management",
        element: <ReportsManagement />,
      },
      {
        path: "reports-management/ems-managment-report",
        element: <EmsReportManagement />,
      },
      {
        path: "reports-management/bed-report",
        element: <BedReport />,
      },
      {
        path: "reports-management/schedule-reports",
        element: <ScheduleReport />,
      },
      {
        path: "public-holidays",
        element: <PublicHolidays />,
      },
      {
        path: "help",
        element: <Help />,
      },
      {
        path: "no-permission",
        element: <EmptyPage />,
      },
      {
        path: "",
        element: <Spinner />,
      },
    ],
  },
];
