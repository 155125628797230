import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Input, Modal, Radio, Row, Select, Tooltip, } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { LiaSearchSolid } from "react-icons/lia";
import { MdOutlineSubject } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { RootState, appDispatch } from "../../app/store";
import SimpleButton from "../../components/buttons/simpleButton/SimpleButton";
import Spinner from "../../components/spinner/Spinner";
import { StorageService } from "../../core/storageService/storageService";
import { fetchGroupList } from "../../features/groups/groupsSlice";
import {
  fetchOnCallLookupHistoryGroupList,
  fetchOnCallLookupHistoryReport,
  fetchScheduleHistoryReport,
  fetchScheduleList,
  fetchUserShiftScheduleReport,
} from "../../features/scheduleReport/scheduleReportSlice";
import { fetchSiteList } from "../../features/unitDepartment/unitDepartmentSlice";
import UserModal from "../user/components/UserModal";
import { ScheduleReportTable } from "./components/scheduleReportTable/ScheduleReportTable";
import "./scheduleReport.scss";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";


interface radioItemsType {
  id: number | null;
  name: string;
}


export interface userShiftSchedulePayloadType {
  groupID: any;
  scheduleId: any;
  startTime: string;
  endTime: string;
  searchQuery: string;
}


export interface onCallLookupHistoryPayloadType {
  groupID: any;
  siteId: any;
  searchQuery: string;
}

export const ScheduleReport = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch<appDispatch>();
  const navigate = useNavigate()

  // const { groupListData } = useSelector(
  //   (state: RootState) => state.scheduleReport.fetchGroupList
  // );

  const { scheduleListData, isLoading: isLoadingFetchScheduleList } = useSelector(
    (state: RootState) => state.scheduleReport.fetchScheduleList
  );

  // const { siteListData } = useSelector(
  //   (state: RootState) => state.scheduleReport.fetchSiteList
  // );

  const { userShiftScheduleReportData, isLoading: fetchUserShiftLoading } = useSelector(
    (state: RootState) => state.scheduleReport.fetchUserShiftScheduleReport
  );

  const { scheduleHistoryReportData, isLoading: fetchScheduleHistoryLoading } = useSelector(
    (state: RootState) => state.scheduleReport.fetchScheduleHistoryReport
  );

  const { onCallLookupHistoryReportData, isLoading: fetchOnCallLookupLoading } = useSelector(
    (state: RootState) => state.scheduleReport.fetchOnCallLookupHistoryReport
  );

  const { groupListData } = useSelector(
    (state: RootState) => state.groups.fetchGroupList
  );

  const { siteListData, isLoading: isLoadingFetchSiteList } = useSelector((state: RootState) => state.unitDepartment.fetchSiteList);
  const { OnCallLookupHistoryGroupListData, isLoading: isLoadingFetchOnCallLookupHistoryGroupList } = useSelector((state: RootState) => state.scheduleReport.fetchOnCallLookupHistoryGroupList);


  const startDate = `${dayjs().format("YYYY-MM-DD")}`;
  const endDate = `${dayjs().add(1, 'year').format("YYYY-MM-DD")}`;
  const dateFormat = "YYYY-MM-DD";


  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [typeOfReportID, setTypeOfReportID] = useState<number>();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<any>("");
  const [scheduleName, setScheduleName] = useState<any>("");
  const [site, setSite] = useState<any>("");
  const [tableData, setTableData] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [isUserCardVisible, setIsUserCardVisible] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [isIpad, setIsIpad] = useState<boolean>(false)
  const [isMobile, setIsmobile] = useState<boolean>(window.innerWidth < 478)
  const [isFirstTimeFiltering, setIsFirstTimeFiltering] = useState(true)
  const [lastFilterValues, setLastFilterValues] = useState({
    siteId: "",
    groupId: "",
    scheduleId: "",
    dateRange: ["", ""]
  });

  const radioItems: radioItemsType[] = [
    {
      id: 1,
      name: "User Shift Schedule",
    },
    {
      id: 2,
      name: "Schedule History",
    },
    {
      id: 3,
      name: "ON Call Lookup History",
    },
  ];

  const getUserDataObj = () => {
    let userDataResult = undefined;

    const userObj = StorageService?.getObjStorage("user");
    if (userObj) {
      const lclUser = JSON?.parse(userObj);
      userDataResult = lclUser?.result?.user_data;
    }

    return userDataResult;
  };

  const openModal = (record: any) => {
    setIsUserCardVisible(true);
    setSelectedUser(record);
  };

  const closeModal = () => {
    setIsUserCardVisible(false);
  };

  const tableColumnDataCreator = (data: any) => {
    const manipulatedData = Object.entries(data)?.map(([key, value]: any[]) => {
      if (value === "Name") {
        return {
          title: value.toUpperCase(),
          dataIndex: key,
          align: "center",
          sorter: (a:any, b:any) => a[key].localeCompare(b[key]),
          sortIcon: ({sortOrder}:any)=>sortOrder==="ascend" ? <IoIosArrowUp /> :  <IoIosArrowDown />,
          render: (text: any, record: any) => (
            <a onClick={() => openModal(record)}>{text}</a>
          ),
        };
      } else {
        return {
          title: value.toUpperCase(),
          dataIndex: key,
          align: "center",
        };
      }
    });

    return manipulatedData;
  };

  const handleRadioButtonChange = (e: any) => {
    setSearchQuery("")
    setIsFirstTimeFiltering(true)
    handleClearFilters()
    setLastFilterValues({
      siteId: "",
      groupId: "",
      scheduleId: "",
      dateRange: ["", ""],
    })
    const scheduleHistoryPayload: userShiftSchedulePayloadType = {
      startTime: "",
      endTime: "",
      groupID: "",
      scheduleId: "",
      searchQuery: ""
    };

    const onCallLookupPayload: onCallLookupHistoryPayloadType = {
      groupID: "",
      siteId: "",
      searchQuery: ""
    };

    setTypeOfReportID(e.target.value);
    if (e.target.value === 2) {
      dispatch(fetchScheduleHistoryReport(scheduleHistoryPayload));
    } else if (e.target.value === 3) {
      dispatch(fetchOnCallLookupHistoryReport(onCallLookupPayload));
    } else {
      dispatch(fetchUserShiftScheduleReport(scheduleHistoryPayload));
    }
  };

  const handleClearFilters = () => {
    setSite("");
    setScheduleName("");
    setGroupName("");
    setDateRange([startDate, endDate]);
  };

  const handleGroupNameChange = (value: any) => {
    setGroupName(value);
    if (typeOfReportID !== 3) {
      dispatch(fetchScheduleList(value))
    };
    setScheduleName("")
  };

  const handleScheduleNameChange = (value: any) => {
    setScheduleName(value);
  };

  const lookupHistoryGroupListPayload = {
    site_id: []
  }

  const handleSiteNameChange = (value: any) => {
    const lookupHistoryGroupListPayload = {
      site_id: [value]
    }
    setSite(value);
    setGroupName("")
    dispatch(fetchOnCallLookupHistoryGroupList(lookupHistoryGroupListPayload));
  };

  const handleDateRangePickerChange = (data: any) => {
    const formattedDates = data?.map((date: any) => `${date.format("YYYY-MM-DD")}`);
    setDateRange(formattedDates);
  };

  const handleCancelFilterModal = () => {
    if (isFirstTimeFiltering) {
      handleClearFilters()
    } else {
      setSite(lastFilterValues?.siteId);
      setScheduleName(lastFilterValues?.scheduleId);
      setGroupName(lastFilterValues?.groupId);
      setDateRange(lastFilterValues?.dateRange);

    }
    if (typeOfReportID !== 3) {
      dispatch(fetchScheduleList(lastFilterValues?.groupId))
    } else {
      const lookupHistoryGroupListPayload = {
        site_id: [lastFilterValues?.siteId]
      }
      dispatch(fetchOnCallLookupHistoryGroupList(lookupHistoryGroupListPayload));
    }
    setIsFilterModalOpen(false);
  };

  const handleFilterModalSubmit = () => {
    setIsFirstTimeFiltering(false)
    const userShiftSchedulePayload: userShiftSchedulePayloadType = {
      startTime: `${dateRange[0]}+00:00:00`,
      endTime: `${dateRange[1]}+00:00:00`,
      groupID: groupName,
      scheduleId: scheduleName,
      searchQuery: searchQuery
    };
    const onCallLookupPayload: onCallLookupHistoryPayloadType = {
      groupID: groupName,
      siteId: site,
      searchQuery: searchQuery
    };
    setLastFilterValues({
      siteId: site,
      groupId: groupName,
      scheduleId: scheduleName,
      dateRange: dateRange
    })

    if (typeOfReportID === 1) {
      dispatch(fetchUserShiftScheduleReport(userShiftSchedulePayload));
    } else if (typeOfReportID === 2) {
      dispatch(fetchScheduleHistoryReport(userShiftSchedulePayload));
    } else {
      dispatch(fetchOnCallLookupHistoryReport(onCallLookupPayload));
    }
    setIsFilterModalOpen(false)
  };

  const [userTableColumn, setUserTableColumn] = useState<any>();

  let userData = getUserDataObj();

  const handleResize = () => {
    setIsmobile(window.innerWidth < 478)
    setIsIpad(window.innerWidth >= 768 && window.innerWidth <= 1024 && (window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth))
  }

  // checking device is mobile or not
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.addEventListener("resize", handleResize)
    }
  }, [])


  useEffect(() => {
    dispatch(fetchGroupList({ searchquery: "", guId: userData?.guid }));
    dispatch(fetchScheduleList(groupName));
    dispatch(fetchSiteList());
  }, []);

  useEffect(() => {
    if (userShiftScheduleReportData.length !== 0) {
      setUserTableColumn(
        tableColumnDataCreator(userShiftScheduleReportData[0])
      );
    }
  }, [userShiftScheduleReportData]);

  useEffect(() => {
    const userShiftSchedulePayload: userShiftSchedulePayloadType = {
      startTime: "",
      endTime: "",
      groupID: "",
      scheduleId: "",
      searchQuery: ""
    };
    dispatch(fetchUserShiftScheduleReport(userShiftSchedulePayload));
    setTypeOfReportID(1);
  }, []);

  useEffect(() => {
    if (typeOfReportID === 1) {
      setTableData(userShiftScheduleReportData.slice(1));
    } else if (typeOfReportID === 2) {
      setTableData(scheduleHistoryReportData);
    } else {
      setTableData(onCallLookupHistoryReportData);
    }
  }, [
    typeOfReportID,
    userShiftScheduleReportData,
    onCallLookupHistoryReportData,
    scheduleHistoryReportData,
  ]);

  //handle schedule report search
  const handleScheduleReportSearch = () => {
    const userShiftSchedulePayload: userShiftSchedulePayloadType = {
      startTime: "",
      endTime: "",
      groupID: groupName,
      scheduleId: scheduleName,
      searchQuery: searchQuery?.trim()
    };
    const onCallLookupPayload: onCallLookupHistoryPayloadType = {
      groupID: groupName,
      siteId: site,
      searchQuery: searchQuery?.trim()
    };
    if (typeOfReportID === 1) {
      dispatch(fetchUserShiftScheduleReport(userShiftSchedulePayload));
    } else if (typeOfReportID === 2) {
      dispatch(fetchScheduleHistoryReport(userShiftSchedulePayload));
    } else {
      dispatch(fetchOnCallLookupHistoryReport(onCallLookupPayload));
    }

  }

  return (
    <div className="schedule-report-container">

      {/* schedule report header section */}
      <Row gutter={[18, 18]}>
        <Col xs={24}    >
          <span style={{ alignItems: 'center', display: 'flex' }} >
            <Tooltip title="Reports management page" placement="bottom">
              <Button size="small" icon={<ArrowLeftOutlined />} onClick={() => navigate('/reports-management')} />
            </Tooltip>
            <span className="text-label-style" style={{ fontSize: '20px', fontWeight: 600, marginLeft: "10px" }}>
              Schedule Reports
            </span>
          </span>
        </Col>
      </Row>


      <Row style={{ marginTop: "20px" }} >

        {/* report type radio button*/}
        <Col xs={24} className="align-left" style={{ textAlign: 'start', marginTop: "10px" }} >
          <Radio.Group onChange={handleRadioButtonChange} defaultValue={1}>
            {radioItems.map((item) => {
              return (
                <Radio value={item?.id} key={item?.id} className="mb-2">
                  <span className="radio-button-labels">{item?.name}</span>
                </Radio>
              );
            })}
          </Radio.Group>
        </Col>

        {/* filter modal */}
        <div style={{ paddingBottom: "10px" }}>
          <Modal
            width={300}
            centered={true}
            closeIcon={false}
            open={isFilterModalOpen}
            footer={[
              <Button key="cancel" onClick={handleCancelFilterModal}>
                Cancel
              </Button>,
              <Button
                key="apply"
                type="primary"
                onClick={handleFilterModalSubmit}
              >
                OK
              </Button>,
            ]}
          >
            <div className="popover-content" style={{ padding: "5px" }}>
              <div className="popover-header">
                <h4>Filter</h4>
                <Button
                  onClick={handleClearFilters}
                  className="clear-all-button"
                >
                  Clear All
                </Button>
              </div>
              {typeOfReportID === 3 && (
                <>

                  <div className="popover-item">
                    <label className="label-text">By Site Name</label>
                    <Select
                      virtual={false}
                      value={site}
                      onChange={handleSiteNameChange}
                      style={{ width: "100%" }}
                    >
                      <Select.Option value="">All</Select.Option>
                      {siteListData?.map((option: any) => (
                        <Select.Option key={option?.id} value={option?.id}>
                          {option?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <div className="popover-item">
                    <label className="label-text">By Group Name</label>
                    <Select
                      virtual={false}
                      value={groupName}
                      onChange={handleGroupNameChange}
                      style={{ width: "100%" }}
                      loading={isLoadingFetchOnCallLookupHistoryGroupList}
                    >
                      <Select.Option value="">All</Select.Option>
                      {OnCallLookupHistoryGroupListData?.map((option: any) => (
                        <Select.Option key={option?.id} value={option?.id}>
                          {option?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                </>
              )}
              {typeOfReportID !== 3 && (
                <div className="popover-item">
                  <label className="label-text">By Group Name</label>
                  <Select
                    virtual={false}
                    value={groupName}
                    onChange={handleGroupNameChange}
                    style={{ width: "100%" }}
                  >
                    <Select.Option value="">All</Select.Option>
                    {groupListData?.data?.map((option: any) => (
                      <Select.Option key={option?.id} value={option?.id}>
                        {option?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>)}
              {typeOfReportID !== 3 && (
                <div className="popover-item">
                  <label className="label-text">By Schedule Name</label>
                  <Select
                    virtual={false}
                    value={scheduleName}
                    onChange={handleScheduleNameChange}
                    style={{ width: "100%" }}
                    loading={isLoadingFetchScheduleList}
                  >
                    <Select.Option value="">All</Select.Option>
                    {scheduleListData?.map((option: any) => (
                      <Select.Option key={option?.id} value={option?.id}>
                        {option?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {typeOfReportID !== 3 && (
                <div className="popover-item">
                  <label className="label-text">By Date Range</label>
                  <RangePicker
                    inputReadOnly={true}
                    allowClear={false}
                    onChange={handleDateRangePickerChange}
                    value={[
                      dayjs(`${dateRange[0]}`, "YYYY-MM-DD"),
                      dayjs(`${dateRange[1]}`, "YYYY-MM-DD"),
                    ]}
                    format={dateFormat}
                  />
                </div>
              )}
            </div>
          </Modal>
        </div>
      </Row>
      <Divider />

      {/* Search bar and button */}
      <Row style={{ marginBottom: "10px" }}>

        <Col xs={24} >
          <Row>

            <Col xs={12} sm={14} md={12} lg={10} xl={8} span={10}>
              <span className="search-bar">
                <Input
                  placeholder="Search by name"
                  value={searchQuery}
                  prefix={<LiaSearchSolid className="search-bar-icon" />}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
              </span>
            </Col>
            <Col xs={3} md={4} lg={2} style={{ marginLeft: '10px' }}>
              <span >
                <SimpleButton
                  text={isMobile ? "" : "Search"}
                  color="primary"
                  icon={<SearchOutlined />}
                  onClick={handleScheduleReportSearch}
                />
              </span>
            </Col>

            <Col xs={2} md={3} lg={3} xl={2} className="align-right" style={{ marginLeft: 'auto' }}>
              <Button
                icon={<MdOutlineSubject />}
                className="filter-button"
                onClick={() => {
                  setIsFilterModalOpen(true);
                }}
              >
                {(isMobile || isIpad) ? "" : "Filter"}
              </Button>
            </Col>

          </Row>
        </Col>

      </Row>

      {/* Table */}
      <Row>
        <Col md={24} lg={24} xl={24}>
          {(fetchUserShiftLoading || fetchScheduleHistoryLoading || fetchOnCallLookupLoading) ? (
            <div style={{ height: "60vh" }}>
              <Spinner />
            </div>
          ) : (
            <>
              <ScheduleReportTable
                data={tableData}
                reportType={typeOfReportID}
                columnData={userTableColumn}
              />
              <UserModal
                visible={isUserCardVisible}
                selectedUser={selectedUser}
                onClose={closeModal}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
