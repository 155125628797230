import { Row, Col, Card } from "antd";
import { ICON_PATH } from "../../constants";
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../app/store";

import "./reportsManagement.scss";


interface cardsType {
  icon: string;
  key: string;
  title: string;
  link: string;
  actions: any
}

export const ReportsManagement = () => {

  const navigate = useNavigate()

  const { fetchSidebarAndCardItemsData } = useSelector((state: RootState) => state.home.fetchSidebarAndCardItems)

  const [cards, setCards] = useState<cardsType[]>([]);



  useEffect(() => {
    if (fetchSidebarAndCardItemsData && fetchSidebarAndCardItemsData?.other?.length > 0) {
      const { other } = fetchSidebarAndCardItemsData

      const bedReportActions = other?.find((item: any) => item?.key === 'other_bed_reports')?.actions || {};

      const scheduleReportActions = other?.find((item: any) => item?.key === 'other_schedule_report')?.actions || {};

      const emsManagementActions = other?.find((item: any) => item?.key === 'other_ems_management_reports')?.actions || {};

      const newCards: cardsType[] = [];

      if (Object.keys(bedReportActions)?.length > 0) {
        newCards?.push({
          icon: "bedReport.png",
          key: "bed_report",
          title: "Bed Reports",
          link: "bed-report",
          actions: bedReportActions
        });
      }

      if (Object.keys(scheduleReportActions)?.length > 0) {
        newCards?.push({
          icon: "scheduleReport.png",
          key: "schedule_report",
          title: "Schedule Reports",
          link: "schedule-reports",
          actions: scheduleReportActions
        });
      }

      if (Object.keys(emsManagementActions)?.length > 0) {
        newCards?.push({
          icon: "emsSvg.png",
          key: "ems_managment_report",
          title: "EMS Management Reports",
          link: "ems-managment-report",
          actions: emsManagementActions
        });
      }

      setCards(newCards);
    }
  }, [fetchSidebarAndCardItemsData])



  const navigateHelper = (link: string, enabled: boolean) => {
    if (enabled) {
      navigate(link)
    }
  }

  return (
    <div className="report-managment-cards-container">
      <Row gutter={[25, 16]} className="row-card-container" >
        {cards?.map((card: any) => {
          const enabled = card?.actions?.view
          return (
            <Col xs={20} sm={20} md={10} lg={10} xl={7}>
              <Card className={`report-managment-card-container ${!enabled ? 'disabled' : ''}`} onClick={() => navigateHelper(card?.link, enabled)} >
                <img src={`${ICON_PATH}${card?.icon}`} alt="" style={{ borderRadius: '10px' }} />
                <p className="report-managment-card-content">{card?.title}</p>
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
};
