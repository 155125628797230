import { CalendarOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Divider, List, Modal, Row, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useLocation } from "react-router-dom";
import { appDispatch } from "../../../../app/store";
import SimpleButton from "../../../../components/buttons/simpleButton/SimpleButton";
import { fetchGroupSchedule } from "../../../../features/shiftRule/shiftRuleSlice";
import { fetchUserDetails, removeLeave } from "../../../../features/users/usersSlice";
import UserModalRangePicker from "../UserModalRangePicker";
import AddLeave from "./components/AddLeave";

interface LeaveDaysTabContentProps {
  fetchUserDetailsData: any;
  handleRangePickerChange: any;
  dateRange: any;
}

interface DateType {
  id: number,
  timezone: string
  start_time: string,
  end_time: string,
  is_custom: boolean,
}
const { confirm } = Modal;

const LeaveDaysTabContent: React.FC<LeaveDaysTabContentProps> = ({
  fetchUserDetailsData,
  handleRangePickerChange,
  dateRange,
}) => {

  const dispatch = useDispatch<appDispatch>();
  const location = useLocation();

  const dayOffdata = fetchUserDetailsData[0]?.days_off;
  const avoidData = fetchUserDetailsData[0]?.avoid_days;

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const startDate = dateRange[0];
  const endDate = dateRange[1];
  const [isDayOffModalOpen, setIsDayOffModalOpen] = useState<boolean>(false)
  const [isAvoidModalOpen, setIsAvoidModalOpen] = useState<boolean>(false)
  const [leaveId, setLeaveId] = useState<number | null>(null);

  const scheduleCalenderStartDate = localStorage.getItem("startDateTime",);
  const scheduleCalenderEndDate = localStorage.getItem("endDateTime");
  const isGroupsOrScheduleGroupEmpty = fetchUserDetailsData[0]?.department.length === 0 || fetchUserDetailsData[0]?.schedule_group?.length === 0

  const userId = fetchUserDetailsData[0]?.id;

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth < 768)
    );
    return () => {
      window.removeEventListener("resize", () =>
        setIsMobile(window.innerWidth < 768)
      );
    };
  });


  const specialitydata = [
    {
      title: "Select Date Range:",
      content: (
        <UserModalRangePicker
          userId={fetchUserDetailsData[0]?.id}
          handleRangePickerChange={handleRangePickerChange}
          dateRange={dateRange}
        />
      ),
    },
  ];

  const handleTagClose = (e: React.MouseEvent<HTMLElement>, displayFormattedDate: string, leaveId: number) => {

    e.preventDefault();
    confirm({
      title: "Delete leave Confirmation",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: (<p>  Are you sure you want to Delete leave <br /><b>{displayFormattedDate}</b> ? </p>),
      onOk() {
        dispatch(removeLeave(leaveId)).then(
          (data) => {
            if (data?.payload?.isSuccess) {
              dispatch(fetchUserDetails({ userId, startDate, endDate }));
              shouldFetchScheduleCalenderData()
            } else {
              e.preventDefault();
              toast.error(data?.payload)
            }
          }
        );
      },

      onCancel() { },
    });

  };

  const toogleDayOffModal = () => {
    setIsDayOffModalOpen(!isDayOffModalOpen)
  }
  const toogleAvoidModal = () => {
    setIsAvoidModalOpen(!isAvoidModalOpen)
  }
  const findIsFutureDate = (startTime: string) => {
    const currentDate = dayjs().subtract(1, 'day')
    const dateFormatted = dayjs(startTime).format('YYYY-MM-DD')
    return dayjs(dateFormatted)?.isAfter(currentDate)
  }

  const handleDayOffEdit = (data: DateType) => {
    const { start_time: startTime, id: leaveId } = data
    const isRemoveDateEligible = findIsFutureDate(startTime)

    if (isRemoveDateEligible) {
      toogleDayOffModal()
      setLeaveId(leaveId)
    } else {
      toast.error('Sorry, only future leaves can be modified.')
    }

  }
  const handleAvoidEdit = (data: DateType) => {
    const { start_time: startTime, id: leaveId } = data
    const isRemoveDateEligible = findIsFutureDate(startTime)
    if (isRemoveDateEligible) {
      toogleAvoidModal()
      setLeaveId(leaveId)
    } else { toast.error('Sorry, only future leaves can be modified.') }
  }


  const handleAddDayOff = () => {
    setLeaveId(null)
    toogleDayOffModal()
  }

  const handleAddAvoid = () => {
    setLeaveId(null)
    toogleAvoidModal()
  }

  const formatDay = (startTime: string, endTime: string) => {
    const dateFormat = 'YYYY-MM-DD'
    const displayDateFormat = 'MMM DD'
    const formattedStartTime = dayjs(startTime).format(dateFormat)
    const formattedEndTime = dayjs(endTime).format(dateFormat)
    const startDateInString = dayjs(formattedStartTime).format(displayDateFormat).toUpperCase()
    const endDateInString = dayjs(formattedEndTime).format(displayDateFormat).toUpperCase()

    return formattedStartTime === formattedEndTime ? startDateInString : `${startDateInString} - ${endDateInString}`
  }

  const handleFetchUserDetails = () => {
    dispatch(fetchUserDetails({ userId, startDate, endDate }));
  }
  const clearLeaveId = () => {
    setLeaveId(null)
  }

  // Check if the current page is  schedule calendar page and refresh the schedule calendar if so. 
  const shouldFetchScheduleCalenderData = () => {
    const isCurrentPageScheduleCalender = location?.pathname?.includes('shift/scheduler')
    const scheduleId = location.state?.selectedData?.selectedData
    if (scheduleId && isCurrentPageScheduleCalender) {
      dispatch(fetchGroupSchedule({
        guid: "",
        start_time: scheduleCalenderStartDate,
        end_time: scheduleCalenderEndDate,
        shift: "",
        shedule_name_id: scheduleId,
        view_type: "",
      })
      );
    }

  }

  return (
    <>
      <List
        dataSource={specialitydata}
        className="user-card-section"
        renderItem={(item) => (
          <>
            <List.Item>
              <Row gutter={[16, 8]} align="middle">
                <Col flex="none">
                  <h4 style={{ margin: 0 }} className="leave-days-content">
                    {item.title}
                  </h4>
                </Col>
                <Col flex="auto">
                  <p style={{ margin: 0 }} className="leave-days-content">
                    {item.content}
                  </p>
                </Col>
              </Row>
            </List.Item>
            <Divider />
            <List>
              <List.Item>
                <Row gutter={[16, 8]} align="middle" >
                  <Col flex="none">
                    <h4 style={{ margin: 0 }} className="leave-days-content">
                      Day off
                    </h4>
                  </Col>
                  <Col flex="auto">
                    <AddLeave open={isDayOffModalOpen} toogleModal={toogleDayOffModal} isDayOffModal={1} userId={userId}
                      handleFetchUserDetails={handleFetchUserDetails}
                      leaveId={leaveId}
                      clearLeaveId={clearLeaveId}
                      shouldFetchScheduleCalenderData={shouldFetchScheduleCalenderData} />
                    <SimpleButton
                      text="Add"
                      color="secondary"
                      icon={<CalendarOutlined />}
                      disabled={isGroupsOrScheduleGroupEmpty}
                      onClick={handleAddDayOff}

                    />

                  </Col>
                  <Divider
                    type="vertical"
                    className="vertical-divider"
                    style={{ height: "50px" }}
                  />
                  <Col flex="auto">
                    {dayOffdata?.map((data: DateType, index: number) => {
                      const { start_time: startTime, end_time: endTime, id: leaveId, is_custom: isCustomLeave, timezone } = data
                      const isFutureDate = findIsFutureDate(startTime)
                      const displayFormattedDate = formatDay(startTime, endTime)
                      return (
                        <Tooltip title={timezone} placement="bottom">
                          <Tag
                            color="#6870b0"
                            bordered={false}
                            icon={isFutureDate && !isCustomLeave && <FiEdit fontSize={"12px"} onClick={() => handleDayOffEdit(data)} style={{ marginRight: '10px', cursor: 'pointer' }} />}
                            closeIcon={isFutureDate}
                            key={leaveId}
                            onClose={(e) => handleTagClose(e, displayFormattedDate, leaveId)}
                            style={{ opacity: isFutureDate ? 1 : 0.5 }}
                            className="leave-days-content mt-1"
                          >
                            {displayFormattedDate}
                          </Tag>
                        </Tooltip>
                      )
                    })}
                  </Col>
                </Row>
              </List.Item>
              <Divider />
              <List.Item>
                <Row gutter={[16, 8]} align="middle">
                  <Col flex="none">
                    <h4 style={{ margin: 0 }} className="leave-days-content">
                      Avoid
                    </h4>
                  </Col>
                  <Col flex="auto">
                    <AddLeave
                      open={isAvoidModalOpen}
                      toogleModal={toogleAvoidModal}
                      isDayOffModal={2}
                      userId={userId}
                      leaveId={leaveId}
                      handleFetchUserDetails={handleFetchUserDetails}
                      clearLeaveId={clearLeaveId}
                      shouldFetchScheduleCalenderData={shouldFetchScheduleCalenderData}
                    />
                    <SimpleButton
                      text="Add"
                      color="secondary"
                      customHtmlType="button"
                      icon={<CalendarOutlined />}
                      disabled={isGroupsOrScheduleGroupEmpty}
                      onClick={handleAddAvoid}
                    />
                  </Col>
                  <Divider
                    type="vertical"
                    className="vertical-divider"
                    style={{ height: "50px" }}
                  />
                  <Col flex="auto">
                    {avoidData?.map((data: DateType) => {
                      const { start_time: startTime, end_time: endTime, id: leaveId, is_custom: isCustomLeave, timezone } = data
                      const isFutureDate = findIsFutureDate(startTime)
                      const displayFormattedDate = formatDay(startTime, endTime)
                      return (
                        <Tooltip title={timezone} placement="bottom">
                          <Tag
                            icon={isFutureDate && !isCustomLeave && <FiEdit fontSize={"12px"} onClick={() => handleAvoidEdit(data)} style={{ marginRight: '10px', cursor: 'pointer' }} />}
                            color="#916191"
                            closeIcon={isFutureDate}
                            key={leaveId}
                            onClose={(e) => handleTagClose(e, displayFormattedDate, leaveId)}
                            style={{ opacity: isFutureDate ? 1 : 0.5 }}
                            className="leave-days-content mt-1"
                          >
                            {displayFormattedDate}
                          </Tag>
                        </Tooltip>
                      )
                    })}
                  </Col>
                </Row>
              </List.Item>
              <Divider />
            </List>
          </>
        )}
      />
    </>
  );
};

export default LeaveDaysTabContent;
